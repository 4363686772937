<template>
  <div>
    <div class="card competitor-list">
      <WeTable
        v-bind:index="false"
        v-bind:header="false"
        v-bind:allowSortIndex="false"
        v-bind:paginatable="false"
        v-bind:searchable="false"
        v-bind:pageDetails="false"
        v-bind:limitable="false"
        v-bind:columns="columns"
        v-bind:actions="actions"
        v-bind:data="getData"
        v-on:on-action="onAction"
      />
    </div>
    <PriceHistory
      v-if="showModal && selectedCompetitor"
      v-bind:competitor="selectedCompetitor"
      v-bind:product="product"
      v-on:close="onCloseHistory"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
const AddCompetitor = () => import("../../../AddCompetitor/Index.vue");
const PriceHistory = () => import("./components/PriceHistory/Index.vue");
export default {
  name: "CompetitorList",
  components: {
    AddCompetitor,
    PriceHistory,
  },
  props: {
    competitors: {
      default: () => [],
    },
    product: {
      default: null,
    },
    competitorLimitExceed: {
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      selectedCompetitor: null,
      actions: [
        {
          icon: "fas fa-history",
          class: "btn-outline-secondary border-0",
          action: "history",
          tooltip: "Fiyat Geçmişi",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "name", th: "Rakip", type: "html", width: "25%" },
        { name: "price", th: "Fiyat", type: "currency", width: "25%" },
        {
          name: "stock_status",
          th: "Stok Durumu",
          type: "stock_status",
          width: "25%",
        },
        {
          name: "last_update",
          th: "Son Güncelleme",
          type: "datetime",
          width: "25%",
        },
      ],
    };
  },
  methods: {
    ...mapActions("priceAnalysis", ["getPriceList", "getPriceAgain"]),
    onAction(data) {
      switch (data.key) {
        case "remove":
          this.$parent.$emit("remove-competitor", {
            product_id: this.product.id,
            competitor_id: data.row.id,
          });
          break;

        case "history":
          this.setSelectedCompetitor(data.row.id);
          break;

        case "retry":
          this.retryGetPrice(data.row.id);
          break;
      }
    },
    setSelectedCompetitor(competitorId) {
      const competitor = this.competitors.find((c) => c.id == competitorId);
      if (competitor) {
        this.getPriceList({
          competitor_id: competitorId,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              competitor.prices = result.data.prices;
              this.selectedCompetitor = competitor;
              this.showModal = true;
            }
          },
        });
      } else {
        this.$toast.error("Rakip Bulunamadı");
      }
    },
    onCloseHistory() {
      this.showModal = false;
      this.selectedCompetitor = null;
    },
    retryGetPrice(competitorId) {
      if (competitorId) {
        this.getPriceAgain({
          id: competitorId,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.product.competitors = this.product.competitors.map(
                (comp) => {
                  if (comp.id == competitorId) {
                    comp.loading = true;
                    comp.status = "pending";
                  }

                  return comp;
                }
              );
              this.$toast.success("Fiyat yeniden alınıyor...");
            } else {
              this.$toast.error("Fiyat alınamıyor");
            }
          },
        });
      }
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getData() {
      // Kendi sitemizdeki ürün bilgisi
      let result = [];

      const competitorsCopy = helper.clone(this.competitors);
      competitorsCopy.forEach((competitor) => {
        // Rakip Adı ve URL varsa link ekle
        let competitorName = `<div class="d-flex align-items-center">${competitor.name}`;

        if (competitor.url && competitor.url.length) {
          const favicon = helper.getFavicon(competitor.url);

          competitorName = `<div class="d-flex align-items-center"><img src="${favicon}" class="mr-2"/> <a href="${competitor.url}" target="_blank">${competitor.name}</a>`;
        }

        if (competitor.tag) {
          competitorName += ` <span class="badge badge-secondary ml-2 p-1">${competitor.tag}</span>`;
        }

        competitorName += "</div>";

        const newItem = {};
        newItem.id = competitor.id; // MongoDB id
        newItem.name = competitorName; // Rakip Adı
        newItem.price = competitor.price || "0.00"; // Rakip ürün fiyatı

        if (competitor.convertedPrice) {
          newItem.price = competitor.convertedPrice;
          newItem.converted_price = competitor.price;
          newItem.converted_price_currency = competitor.currency;
        }

        newItem.stock_status =
          (competitor.status !== "error" && competitor.availability) || false; // Stok Durumu
        newItem.tag = competitor.tag || null;
        newItem.currency_code = { code: this.product.currency }; // Ürün para birimine göre formatla

        let updatedAt = competitor.updatedAt || null;
        if (updatedAt) {
          updatedAt = helper.timestampToDate(updatedAt);
        }

        newItem.last_update = updatedAt;
        newItem.loading =
          competitor.status == "pending" || competitor.loading || false;
        newItem.status = competitor.status;

        result.push(newItem);
      });

      if (result && result.length) {
        let url = this.config["site.url"] || this.product.name;
        let favicon = null;

        if (helper.isURL(url)) {
          favicon = helper.getFavicon(url);
          url = `<img src="${favicon}" class="mr-1"/> ${
            helper.urlParser(url).hostname
          }`;
        }

        // E-Ticaret sitesinin bilgileri
        result.push({
          name: `${url} <i class="fas fa-map-marker-alt ml-1 mr-2"></i>`,
          price: this.product.price,
          stock_status: this.product.quantity > 0,
          last_update: this.product.last_update,
          currency_code: {
            code: this.product.currency,
          },
          buttons: false,
          selected: true,
        });
      }

      return result.sort((a, b) => a.price - b.price);
    },
  },
};
</script>
<style lang="scss">
.competitor-list {
  position: relative;
  top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>